import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

import * as actions from '../../../../actions/requests'
import './style.less'
import { AppState } from '../../../../reducers'
import Requests from '../../../../components/Requests'
import { formatRequestsDictForCalendarEvents } from '../../../../util/requests';

function mapStateToProps({ requestsReducer, authReducer }: AppState) {
    const isCalendarEvents = Object.keys(requestsReducer.requestCalendarEvents || {}).length;
    const finalRequestsArr = isCalendarEvents ? formatRequestsDictForCalendarEvents(requestsReducer.requestCalendarEvents) : requestsReducer.requests;
    return {
        requests: finalRequestsArr,
        hasError: requestsReducer.hasError,
        errorCode: requestsReducer.errorCode,
        isFetching: requestsReducer.fetching,
        page: requestsReducer.page,
        count: requestsReducer.count,
        limit: requestsReducer.limit,
        userId: authReducer.profile && authReducer.profile._id,
        facilityTZ: authReducer.profile && authReducer.profile.FacilityTimeZone || "",
        unassigned: false,
        requestTitle: 'My Requests',
        emptyMessage: 'No Requests',
    }
}

function mapDispatchToOpenedProps(dispatch: ThunkDispatch<any, any, actions.RequestsAction>) {
    return {
        onFetchRequests: (userId: string, pageNo = undefined, filter = undefined, facilityTZ: string = "", fetchAllData: boolean = false, showAllRequests: boolean = false) => {
            console.log(userId, 'UserId')
            return dispatch(actions.fetchAndFilter(userId, 'OPEN', true, 'default', pageNo, filter, false, facilityTZ, fetchAllData, showAllRequests))
        },
        onBgFetchRequests: (userId: string, pageNo = undefined, filter = undefined, facilityTZ: string = "", fetchAllData: boolean = false, showAllRequests: boolean = false) => {
            return dispatch(actions.fetchAndFilter(userId, 'OPEN', false, 'default', pageNo, filter, false, facilityTZ, fetchAllData, showAllRequests))
        },
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToOpenedProps,
)(withRouter(Requests))
