import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

import * as actions from '../../actions/auth'
import './style.less'
import { AppState } from '../../reducers'

import { Form, Button, Message, Checkbox } from 'semantic-ui-react'
import { UserProfile, CurrentUser } from '../../types'
import { updateProfile } from '../../services/Users'
import { isStaff, isPowerUser } from '../../services/Permissions';

import ChangePassword from './ChangePassword'
import EditableImage from '../../components/EditableImage'
import MaskedInput from 'react-text-mask';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { toast } from 'react-toastify'
import { Group } from '../../types/Group'
import { listGroups } from '../../services/Groups'
import { sendToast } from '../../util'
interface Props extends RouteComponentProps {
    profile: UserProfile | null
    currentUser: CurrentUser | null
    setProfile: (profile: UserProfile) => any
    federatedAuthUser: boolean
}

interface State {
    FirstName: string
    LastName: string
    Phone: string | null
    Cell: string | null
    Icon: string | null
    showPasswordForm: boolean
    saving: boolean
    saveError: string | null
    success: boolean
    modalOpen: boolean
    isChecked: boolean
    emailAnnouncementAlert: string
    Groups?: Group[], 
    selectedGroups?: Group[]
    groupsLoading: boolean
}

class ProfileSettings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        if (props.profile) {
            const { FirstName, LastName, Phone, Cell, Icon, AnnouncementAlerts = false, EmailAnnouncementAlert = '' } = props.profile
            this.state = {
                FirstName,
                LastName,
                Phone,
                Cell,
                Icon,
                showPasswordForm: false,
                saving: false,
                saveError: null,
                success: false,
                modalOpen: false,
                isChecked: AnnouncementAlerts,
                emailAnnouncementAlert: EmailAnnouncementAlert,
                Groups: [],
                selectedGroups: this.props.profile ? this.props.profile.Groups : [],
                groupsLoading: false
            }
        }
    }

    componentDidMount() {
        this.loadGroups()
    }

    async loadGroups() {
        try {
            this.setState({
                groupsLoading: true
            });
            const groups = await listGroups()
            this.setState({
                Groups: groups
            })
        } catch (error) {
            console.error('Error fetching data:', error);
            sendToast("error", error instanceof Error ? error.message : "Something went wrong while fetching groups");
        } finally {
            this.setState({
                groupsLoading: false
            });
        }
    }

    handleChange(property: keyof State, value: string | boolean | null | Group[]) {
        this.setState({
            [property]: value,
        } as Pick<State, keyof State>)
    }

    async handleSave() {
        const { FirstName, LastName, Cell, Phone, Icon, isChecked, emailAnnouncementAlert } = this.state
        this.setState({
            saving: true,
            saveError: null,
            success: false,
        })
        try {
            const newProfile = await updateProfile({
                FirstName,
                LastName,
                Phone,
                Cell,
                Icon,
                AnnouncementAlerts: isChecked,
                EmailAnnouncementAlert: emailAnnouncementAlert, 
                Groups: this.state.selectedGroups
            })
            this.setState({
                saving: false,
                saveError: null,
                success: true,
            })
            this.props.setProfile(newProfile)
        } catch (e) {
            console.log(e)
            this.setState({
                saving: false,
                saveError: e.message,
                success: false,
            })
        }
    }

    renderErrorMessage(header: string, body: string) {
        return (
            <Message error>
                <Message.Header>{header}</Message.Header>
                <p>{body}</p>
            </Message>
        )
    }

    renderSucessMessage(header: string, body: string) {
        return (
            <Message success>
                <Message.Header>{header}</Message.Header>
                <p>{body}</p>
            </Message>
        )
    }

    onPasswordChangeSuccess() {
        toast.success("Successfully updated password", {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        })
        this.setState({
            saveError: null,
            success: true,
            showPasswordForm: false,
        })
    }

    onPasswordChangeError(e: string) {
        this.setState({
            success: false,
            saveError: e,
        })
    }

    render() {
        if (!this.props.profile) return null
        const placeholderSrc = `${process.env.PUBLIC_URL}/avatar_placeholder.svg`

        return (
            <div className="ProfileSettings">
                <div className="formBox">
                    <EditableImage
                        url={this.state.Icon}
                        placeholder={placeholderSrc}
                        onSelected={newUrl => this.handleChange('Icon', newUrl)}
                    />
                    <div className="username">{this.props.profile.Username}</div>
                    <Form
                        className="settings-form"
                        onSubmit={this.handleSave.bind(this)}
                        error={this.state.saveError ? true : false}
                        success={this.state.success}
                    >
                        <Form.Group widths="equal">
                            <Form.Input
                                fluid
                                label="First name"
                                onChange={e => this.handleChange('FirstName', e.currentTarget.value)}
                                value={this.state.FirstName || ''}
                                placeholder="First name"
                            />
                            <Form.Input
                                fluid
                                label="Last name"
                                onChange={e => this.handleChange('LastName', e.currentTarget.value)}
                                value={this.state.LastName || ''}
                                placeholder="Last name"
                            />
                        </Form.Group>
                        <Form.Field>
                            <label>Email</label>
                            <Form.Input
                                value={this.props.profile.Email || ''}
                                placeholder="Email"
                                type="email"
                                autoComplete="false"
                                name="registeremail"
                                disabled
                            />
                        </Form.Field>
                        {!this.props.federatedAuthUser ?
                            <Form.Field>
                                <a onClick={() => this.setState({ showPasswordForm: true })}>Change Password</a>
                                {/* check if federatedAuthUser is false from redux store and only then show the <ChangePassword component  */}
                                {this.state.showPasswordForm && (
                                    <ChangePassword
                                        onError={this.onPasswordChangeError.bind(this)}
                                        onSuccess={this.onPasswordChangeSuccess.bind(this)}
                                        currentUser={this.props.currentUser}
                                    />
                                )}
                            </Form.Field> : <></>
                        }   
                        <Form.Field>
                            <label>Phone</label>
                            <PhoneInput
                                style={{ padding: '5px' }}
                                defaultCountry="US"
                                value={this.state.Phone || ""}
                                placeholder="Enter phone number"
                                onChange={(phone) => this.handleChange("Phone", phone)}
                            />
                        </Form.Field>
                        <Form.Field required={true}>
                            <label>Cell</label>
                            <PhoneInput
                                style={{ padding: '5px' }}
                                defaultCountry="US"
                                value={this.state.Cell || ""}
                                placeholder="Enter cell number"
                                onChange={(phone) => this.handleChange("Cell", phone)}
                            />
                        </Form.Field>
                        {(isStaff(this.props.profile) || isPowerUser(this.props.profile)) && (
                            <>
                                <Form.Field>
                                <Form.Input
                                    fluid
                                    label="Email for announcement alerts"
                                    onChange={e => this.handleChange('emailAnnouncementAlert', e.currentTarget.value)}
                                    value={this.state.emailAnnouncementAlert || ''}
                                    placeholder="Email for announcement alerts"
                                />
                                </Form.Field>
                                <Form.Field>
                                    <Checkbox 
                                        toggle 
                                        label={"Announcement Alerts"} 
                                        checked={this.state.isChecked}
                                        onChange={async (e, data) => {
                                                e.preventDefault();
                                                this.setState({
                                                    isChecked: !this.state.isChecked
                                                })
                                            }
                                        }
                                    /> 
                                </Form.Field>
                            </>
                        )}
                        <Form.Field>
                            <label>Groups</label>
                            <Form.Dropdown
                                placeholder='Select Groups'
                                value={this.state.selectedGroups ? this.state.selectedGroups.map(group => String(group._id)) : []}
                                fluid
                                loading={this.state.groupsLoading}
                                multiple
                                search
                                selection
                                options={this.state.Groups ? this.state.Groups.map(group => ({ key: String(group._id), text: group.Name, value: String(group._id) })) : []}
                                onChange={(e, data) => {
                                    if (data.value) {
                                        const selectedGroups = this.state.Groups ? this.state.Groups.filter(group => {
                                            if (data && data.value && typeof data.value === 'object') { // check if data.value is an array
                                                return data.value.includes(String(group._id))
                                            }
                                        }) : []
                                        this.setState({ selectedGroups })
                                    }
                                }}
                            />
                        </Form.Field>

                        <Form.Field>
                            {this.renderErrorMessage('Could not update profile', this.state.saveError || '')}
                            {this.renderSucessMessage('Success', 'Profile saved successfully!')}
                        </Form.Field>
                        <Button
                            type="submit"
                            primary
                            floated="right"
                            loading={this.state.saving}
                            disabled={this.state.saving}
                        >
                            Save Changes
                        </Button>
                    </Form>
                </div>
            </div>
        )
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        profile: authReducer.profile,
        currentUser: authReducer.currentUser,
        federatedAuthUser: authReducer.federatedAuthUser
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, actions.AuthAction>) {
    return {
        setProfile: (profile: UserProfile) => dispatch(actions.setProfile(profile)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(ProfileSettings))
