import React, { useCallback, useEffect, useState } from 'react';
import { getSignedInstanceImageUrls } from '../../services/ImageUpload';
import S3FileUpload from '../../components/S3FileUpload';
import PhotoGrid from '../../components/PhotoGrid';
import { updateServiceInstance } from '../../services/ServiceInstances';
import { sendToast } from '../../util';

const SVCEventImageUploader = ({ svcInstance, updateInstanceImageFilenames }) => {
	const [imageUrls, setImageUrls] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const queryId = String(svcInstance._id);
	const facilityId = svcInstance.Facility;

	const handleFilePick = useCallback(async (url) => {
		try {
			if (url) {
				const newFilename = url.split('/').pop();
				const [newSignedImageUrl] = await getSignedInstanceImageUrls(queryId, [newFilename], facilityId);
				setImageUrls((prevUrls) => [...prevUrls, newSignedImageUrl]);
				return newFilename;
			}
		} catch (err) {
			sendToast('warn', 'Error uploading image');
			return null;
		}
	}, [queryId, facilityId]);

	const loadImages = useCallback(async () => {
		setLoading(true);
		try {
			if (svcInstance.imageFilenames && svcInstance.imageFilenames.length) {
				const signedImgUrls = await getSignedInstanceImageUrls(queryId, svcInstance.imageFilenames, facilityId);
				setImageUrls(signedImgUrls);
			}
		} catch (err) {
			sendToast('warn', 'Error loading event images');
		} finally {
			setLoading(false);
		}
	}, [svcInstance, queryId, facilityId]);

	const handleUpdateInstanceImageFilenames = useCallback(async (filenames : string[]) => {
		try {
			const updatedInstance = {
				...svcInstance,
				imageFilenames: [...(svcInstance.imageFilenames || []), ...filenames],
			};
			await updateServiceInstance(updatedInstance);
			updateInstanceImageFilenames(updatedInstance.imageFilenames);
		} catch (e) {
			sendToast('warn', 'Failed to update image list');
		}
	}, [svcInstance, updateInstanceImageFilenames]);

	useEffect(() => {
		loadImages();
	}, [loadImages]);

	return (
		<>
			<S3FileUpload
				folderName={`service-instances/${facilityId}/event-images/${queryId}`}
				onUploaded={handleFilePick}
				accept={['image/*']}
				multiple
				postUpload={handleUpdateInstanceImageFilenames}
			/>
			{loading ? <p>Loading images...</p> : <PhotoGrid imageUrls={imageUrls} />}
		</>
	);
};

export default SVCEventImageUploader;
