import React, { useEffect, useState } from "react";
import Board, { moveCard } from "@asseinfo/react-kanban"
import "@asseinfo/react-kanban/dist/styles.css";
import { Dimmer, Dropdown, Input, Loader } from 'semantic-ui-react';
import { toast } from "react-toastify";
import { updateMenuAttendee, createMenuAttendee, updateMenuAttendeeDeclineReason, updateMenuAttendeeDeliveryReason, deleteMenuAttendee } from '../../../services/Menus';
import { createSvcAttendee, updateSvcAttendee, deleteSvcAttendee } from '../../../services/ServiceInstances';

// Use your own styles to override the default styles
import "./style.less";
import { declineOptions } from "../../../util/data";
import { sortKanbanList } from "../../../util";

function ControlledBoard(props) {
  // You need to control the state yourself.
  const [controlledBoard, setBoard] = useState(props.board);
  const [selectedSortingOption, setSelectedSortingOption] = useState('');
  const [sortLoading, setSortLoading] = useState(false);
  useEffect(() => {
    setBoard(props.board);
  }, [props.board])

  const sortingOptions = [
      { key: 'FirstName', text: 'First Name', value: 'FirstName' },
      { key: 'LastName', text: 'Last Name', value: 'LastName' },
      { key: 'RoomName', text: 'Room Name', value: 'RoomName' },
  ];

  async function handleCardMove(_card, source, destination) {
    const currentBoard = controlledBoard;
    try {
      props.loader(true);
      setSelectedSortingOption(''); // Reset sorting option
      delete _card.declineReason;
      delete _card.participation;
      if (source.fromColumnId !== destination.toColumnId && destination.toColumnId !== 0) {
        const updatedBoard = moveCard(controlledBoard, source, destination);
        setBoard(updatedBoard);
        props.setFullBoard(updatedBoard);
        let res;
        let status;
        const isMovedForFirstTime = source.fromColumnId === 0 || !_card.status;
        if (isMovedForFirstTime) {
          if (destination.toColumnId === 1) {
            status = 'attended';
          }
          if (destination.toColumnId === 2) {
            _card.deliveryReason = "Ill";
            status = 'delivery';
          }

          if (destination.toColumnId === 3) {
            _card.declineReason = 'Refused';
            status = 'removed';
          }

          delete _card._id;
          delete _card.id;

          _card.status = status;
          _card.Facility = props.facilityId;
          _card.menuId = props.menuId;

          if (_card.status === 'attended') {
            props.addNewAttendee(_card.title);
          }
          if (props.menuId) {
            res = await createMenuAttendee({
              Facility: props.facilityId,
              menuId: props.menuId,
              registrantId: _card.registrantId,
              registrant: { FirstName: _card.registrant.FirstName, LastName: _card.registrant.LastName },
              status: status
            });
          } else if (props.svcInstanceId) {
            res = await createSvcAttendee({
              Facility: props.facilityId,
              svcInstanceId: props.svcInstanceId,
              registrantId: _card.registrantId,
              registrant: { FirstName: _card.registrant.FirstName, LastName: _card.registrant.LastName },
              status: status,
              svcType: 'menu',
              thisAndFollowingDate: props.thisAndFollowingDate
            });
          }
          _card.id = res;
          _card._id = res;
        }
        else {
          if (destination.toColumnId === 1) {
            props.addNewAttendee(_card.title);
            _card.deliveryReason = null;
            _card.declineReason = null;
            if (props.menuId) {
              res = await updateMenuAttendee(_card.id, 'attended');
            } else if (props.svcInstanceId) {
              res = await updateSvcAttendee({ attendeeObjId: _card.id, status: 'attended', svcInstanceId: props.svcInstanceId, svcType: 'menu', thisAndFollowingDate: props.thisAndFollowingDate });
            }
            _card.status = 'attended';
          } else if (destination.toColumnId === 2) {
            if (props.menuId) {
              res = await updateMenuAttendee(_card.id, 'delivery');
            } else if (props.svcInstanceId) {
              res = await updateSvcAttendee({ attendeeObjId: _card.id, status: 'delivery', svcInstanceId: props.svcInstanceId, svcType: 'menu', thisAndFollowingDate: props.thisAndFollowingDate });
            }
            _card.status = 'delivery';
            _card.deliveryReason = 'Ill';
            props.removeAttendee(_card.title);
            if (props.menuId) {
              res = await updateMenuAttendee(_card.id, 'delivery');
            } else if (props.svcInstanceId) {
              res = await updateSvcAttendee({ attendeeObjId: _card.id, status: 'delivery', svcInstanceId: props.svcInstanceId, svcType: 'menu', thisAndFollowingDate: props.thisAndFollowingDate });
            }
          } else if (destination.toColumnId === 3) {
            _card.status = 'removed';
            _card.declineReason = 'Refused';
            props.removeAttendee(_card.title);
            if (props.menuId) {
              res = await updateMenuAttendee(_card.id, 'removed');
            } else if (props.svcInstanceId) {
              res = await updateSvcAttendee({ attendeeObjId: _card.id, status: 'removed', svcInstanceId: props.svcInstanceId, svcType: 'menu', thisAndFollowingDate: props.thisAndFollowingDate });
            }
          }
        }
      }
      if (source.fromColumnId !== destination.toColumnId && destination.toColumnId === 0) {
        // This block is invoked when a card is moved back to the initial column (columnId 0) from a different column.
        // This usually signifies that the user recognizes a mistake in the card's previous placement and wants to undo that action.
        // In response, we not only move the card back to the initial column in the UI but also remove its corresponding entry from the database.
        // This is done to ensure data integrity and to revert the state back to what it was before the 'mistaken' move.
        const updatedBoard = moveCard(controlledBoard, source, destination);
        setBoard(updatedBoard);
        props.setFullBoard(updatedBoard);
        props.removeAttendee(_card.title);
        if (props.menuId) {
          await deleteMenuAttendee(_card.id);
        } else if (props.svcInstanceId) {
          await deleteSvcAttendee({ id: _card.id, svcType: 'menu', thisAndFollowingDate: props.thisAndFollowingDate, svcInstanceId: props.svcInstanceId });
        }
        delete _card.Facility;
        delete _card.menuId;
        delete _card.status;
        delete _card._id;
        delete _card.id;
        _card.deliveryReason = null;
        _card.declineReason = null;
        _card.id = 'temp' + _card.registrantId;
        _card.key = 'temp' + _card.registrantId;
      }
    } catch (err) {
      setBoard(currentBoard);
      props.setFullBoard(currentBoard);
      const errorMessage = err.message ? err.message : 'An error occurred. Unable to move the card.';
      toast.error(errorMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } finally {
      props.loader(false);
    }
  }

  const RenderCard = (card) => {
    const [dropDownloading, setDropDownloading] = useState(false);
    const deliveryOptions = [
      {
        key: '0',
        text: 'Ill',
        value: 'Ill',
      },
      {
        key: '1',
        text: 'Preferred',
        value: 'Preferred',
      }
    ];

    const declineOptions = [
      {
        key: '0',
        text: 'Refused',
        value: 'Refused',
      },
      {
        key: '1',
        text: 'Out Of Building',
        value: 'Out Of Building',
      },
      {
        key: '2',
        text: 'Hospice',
        value: 'Hospice',
      }
    ];
    const isDeclineType = declineOptions.map(item => item.value).some(item => item === card.declineReason);
    const isDeliveryType = deliveryOptions.map(item => item.value).some(item => item === card.deliveryReason);
    return (
        <div className={`react-kanban-card`}>
            <div className="react-kanban-column-header">
                <div className="react-kanban-card__title">{card.title}</div>
            </div>
            <div className="react-kanban-card__roomName">
                {card.roomName && (
                    <p>
                        <span style={{ color: 'grey' }}>Room Name: </span>
                        {card.roomName}
                    </p>
                )}
                {isDeliveryType || isDeclineType ? (
                    <>
                        {card.roomName && <hr style={{ border: '1px solid #F0F0F0' }} />}
                        <Dropdown
                            placeholder={isDeclineType ? 'Reason for decline'  : "Select a reason"}
                            fluid
                            selection
                            loading={dropDownloading}
                            options={isDeclineType ? declineOptions : deliveryOptions}
                            defaultValue={card.declineReason || card.deliveryReason || ''}
                            onChange={async (e, data) => {
                                try {
                                    setDropDownloading(true);
                                    if (isDeclineType) {
                                      if (props.menuId) {
                                        await updateMenuAttendeeDeclineReason(card.id, card.status, data.value);
                                      } else if (props.svcInstanceId) {
                                        await updateSvcAttendee({ attendeeObjId: card.id, status: card.status, svcInstanceId: props.svcInstanceId, svcType: 'menu', declineReason: data.value });
                                      }
                                    } else {
                                      if(props.menuId){
                                        await updateMenuAttendeeDeliveryReason(card.id, card.status, data.value)
                                      } else if(props.svcInstanceId){
                                        await updateSvcAttendee({ attendeeObjId: card.id, status: card.status, svcInstanceId: props.svcInstanceId, svcType: 'menu', deliveryReason: data.value });
                                      }
                                    }
                                    setDropDownloading(false);
                                } catch (error) {
                                    setDropDownloading(false);
                                    toast.error('Could not update', {
                                        position: 'bottom-center',
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                    });
                                }
                            }}
                        />
                    </>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
  };
  const handleSortingDropdownChange = (value) => {
      setSortLoading(true);
      setSelectedSortingOption(value);
      sortKanbanList(value, controlledBoard);
      setSortLoading(false);
  };

  return (
    <>
      <Dimmer active={sortLoading} inverted>
        <Loader active={sortLoading} />
      </Dimmer>
      <div style={{ display: "flex", gap: "10px" }}>
        <Dropdown
          style={{ marginLeft: '10px', marginTop: '10px' }}
          placeholder={'Sort By'}
          closeOnEscape
          value={selectedSortingOption}
          selection
          scrolling
          options={sortingOptions}
          onChange={(e, { value = "" }) => handleSortingDropdownChange(value)}
        />
        {props.searchable && (
          <div style={{ marginTop: "10px" }}>
            <Input
              icon="search"
              placeholder="Search by First or Last Name..."
              value={props.searchQuery}
              onChange={(e) => props.searchQueryChangeHandler(e.target.value)}
              style={{ display: 'block' }}
            />
          </div>
        )}
      </div>
      <Board onCardDragEnd={handleCardMove} disableColumnDrag renderCard={RenderCard} style={{ position: 'relative' }}>
        {controlledBoard}
      </Board>
    </>
  );
}


function KanbanBoards(props) {
  const { loading, setLoading } = props;
  const [fullBoard, setFullBoard] = useState(props.board);
  const [filteredBoard, setFilteredBoard] = useState(props.board);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setFullBoard(props.board);
    setFilteredBoard(props.board);
  }, [props.board]);

  useEffect(() => {
    if (searchQuery) {
      const filteredColumns = fullBoard.columns.map((column) => ({
        ...column,
        cards: column.cards.filter((card) => {
          const firstName = card && card.registrant && card.registrant.FirstName && card.registrant.FirstName.toLowerCase() || "";
          const lastName = card && card.registrant && card.registrant.LastName && card.registrant.LastName.toLowerCase() || "";
          return (
            firstName.includes(searchQuery.toLowerCase()) ||
            lastName.includes(searchQuery.toLowerCase())
          );
        }),
      }));
      setFilteredBoard({ ...fullBoard, columns: filteredColumns });
    } else {
      setFilteredBoard(fullBoard);
    }
  }, [searchQuery, fullBoard]);

  const handleSetLoading = (value) => {
    setLoading(value);
  };

  const searchQueryChangeHandler = (value) => {
    setSearchQuery(value);
  }

  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader active={loading}>loading....</Loader>
      </Dimmer>
      <ControlledBoard
        board={filteredBoard}
        facilityId={props.facilityId}
        menuId={props.menuId}
        svcInstanceId={props.svcInstanceId}
        addNewAttendee={props.addNewAttendee}
        removeAttendee={props.removeAttendee}
        loader={handleSetLoading}
        thisAndFollowingDate={props.thisAndFollowingDate}
        searchQuery={searchQuery}
        searchQueryChangeHandler={searchQueryChangeHandler}
        searchable={props.searchable}
        setFullBoard={setFullBoard}
      />
    </>
  );
}

export default KanbanBoards;