import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import './style.less'
import { OrderIdColorDict, Request, reportRequestType } from '../../types'
import { Loader, Dimmer, Icon, Pagination, Dropdown } from 'semantic-ui-react';
import RequestsList from '../RequestsList'
import { registerOnNotificationHandler } from '../../services/PushNotifications'
import RequestCalenderView from '../RequestsList/RequestCalenderView';
import LoadMore from '../LoadMore';
import { sortByKey } from '../../util/sortData';
const REFRESH_REQUESTS_TIME_MS = 20000
interface Props extends RouteComponentProps {
    onFetchRequests: (userId: string, pageNo?, filter?, facilityTZ?: string, fetchAllData?: boolean, showAllRequests?: boolean) => void;
    onBgFetchRequests?: (userId: string, pageNo?, filter?, facilityTZ?: string, fetchAllData?: boolean, showAllRequests?: boolean) => any
    requests: Request[]
    count: number
    page: number
    limit: number
    hasError: boolean
    errorCode: string | null
    isFetching: boolean
    userId: string | null
    setOpenFetchAllData?: React.Dispatch<React.SetStateAction<boolean>>;
    unassignedRequests?: Request[]
    unassignedRequestCount?: number
    unassignedRequestPage?: number
    unassignedRequestLimit?: number
    unassignedRequestHasError?: boolean
    unassignedRequestErrorCode?: string | null
    unassignedRequestIsFetching?: boolean
    unassigned: boolean
    setAcceptedAndClosedFetchAllData?: React.Dispatch<React.SetStateAction<boolean>>;
    requestTitle?: string
    emptyMessage?: string
    facilityTZ?: string
    refresh?: () => void;
    setPageNo?: React.Dispatch<React.SetStateAction<number>>;
    powerAndFacilityUsers?: boolean;
    updateAcceptedAndClosedRequestsFilters?: (status: reportRequestType) => void;
    hasMorePages?: boolean;
    modalView?: boolean;
    onViewUpdate?: (listView: boolean) => void;
    isListView?: boolean;
    staffDateRange?: {
        fromDate: Date;
        toDate: Date;
    };
    statusFilter?: string
    isUpcomingRequestsDue?: boolean;
    acceptedRequest?:boolean;
    closedRequest?:boolean;
    showSort?: boolean;
    requestSortOptions?: object[];
    sortDropdownValue?: string;
    setSortDropdownValue?: React.Dispatch<React.SetStateAction<string>>;
    orderIdColorDict?: OrderIdColorDict;
    showAllRequests?: boolean;
}
interface State {
    isListView: boolean;
    filter: {
        startDate: string | Date;
        endDate: string | Date;
    };
    pageNo: number,
    noPagesShown: number;
    statusFilter: string;
    showAllRequests: boolean;
}
class Requests extends React.Component<Props, State> {
    intervalId: number | null = null;
    unsubscribeHandler: firebase.Unsubscribe | null = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            isListView: this.props.isListView === false ? false : true,
            filter: {
                startDate: this.props.staffDateRange && this.props.staffDateRange.fromDate || new Date(),
                endDate: this.props.staffDateRange && this.props.staffDateRange.toDate || new Date(),
            },
            pageNo: 1,
            noPagesShown: 0,
            statusFilter: this.props.statusFilter || '',
            showAllRequests: this.props.showAllRequests || false,
        };
    }
    componentDidMount() {
        if (!this.props.userId) return;
        this.props.onFetchRequests(this.props.userId, this.state.pageNo, this.state.filter, this.props.facilityTZ || "", this.state.isListView ? false : true, this.props.showAllRequests);
        if (this.props.onBgFetchRequests) {
            //Creating a refresh api call for a particular interval
            this.intervalId = window.setInterval(() => {
                this.props.onBgFetchRequests &&
                    this.props.userId &&
                    this.props.onBgFetchRequests(this.props.userId, this.state.pageNo, this.state.filter, this.props.facilityTZ || "", this.state.isListView ? false : true, this.props.showAllRequests);
            }, REFRESH_REQUESTS_TIME_MS); // Refresh requests on notification
            this.unsubscribeHandler = registerOnNotificationHandler(
                () => this.props.onBgFetchRequests && this.props.onBgFetchRequests.bind(this),
                this.props.history,
            );
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (
            this.state.pageNo !== prevState.pageNo ||
            this.state.isListView !== prevState.isListView ||
            this.state.filter !== prevState.filter
        ) {
            if (this.state.isListView) {
                //listView
                if (this.props.powerAndFacilityUsers) {
                    if (this.props.unassigned) {
                        this.props.setAcceptedAndClosedFetchAllData && this.props.setAcceptedAndClosedFetchAllData(false);
                    } else {
                        this.props.setOpenFetchAllData && this.props.setOpenFetchAllData(false);
                    }
                } else {
                    if (this.intervalId) window.clearInterval(this.intervalId);
                    this.props.onFetchRequests(this.props.userId as string, this.state.pageNo, this.state.filter, this.props.facilityTZ || "", false, this.props.showAllRequests);
                    this.intervalId = window.setInterval(() => {
                        this.props.onBgFetchRequests &&
                            this.props.userId &&
                            this.props.onBgFetchRequests(this.props.userId, this.state.pageNo, this.state.filter, this.props.facilityTZ || "", false, this.props.showAllRequests);
                    }, REFRESH_REQUESTS_TIME_MS);
                }
            } else {
                //CalendarView
                if (this.props.powerAndFacilityUsers) {
                    if (this.props.unassigned) {
                        this.props.setAcceptedAndClosedFetchAllData && this.props.setAcceptedAndClosedFetchAllData(true); 
                    } else {
                        this.props.setOpenFetchAllData && this.props.setOpenFetchAllData(true);
                    }
                } else {
                    if (this.intervalId) window.clearInterval(this.intervalId);
                    this.props.onFetchRequests(this.props.userId as string, 1, this.state.filter, this.props.facilityTZ || "", true, this.props.showAllRequests);
                    this.intervalId = window.setInterval(() => {
                        this.props.onBgFetchRequests &&
                            this.props.userId &&
                            this.props.onBgFetchRequests(this.props.userId as string, 1, this.state.filter, this.props.facilityTZ || "", true, this.props.showAllRequests);
                    }, REFRESH_REQUESTS_TIME_MS);
                }
            }
        }
        if (this.props.staffDateRange !== (prevProps.staffDateRange && prevProps.staffDateRange)) {
            if (this.props.staffDateRange && this.props.staffDateRange.fromDate && this.props.staffDateRange.toDate)
                this.setState({
                    filter: {
                        startDate: this.props.staffDateRange && this.props.staffDateRange.fromDate,
                        endDate: this.props.staffDateRange && this.props.staffDateRange.toDate,
                    },
                });
        }

        if (this.props.showAllRequests !== prevProps.showAllRequests) {
            this.props.onFetchRequests(this.props.userId as string, this.state.pageNo, this.state.filter, this.props.facilityTZ || "", this.state.isListView ? false : true, this.props.showAllRequests);
        }
    }

    handleRefresh() {
        if (this.state.isListView) {
            this.props.onFetchRequests(this.props.userId as string, this.state.pageNo, this.state.filter,this.props.facilityTZ || "", false, this.props.showAllRequests);
        } else {
            this.props.onFetchRequests(this.props.userId as string, 1, this.state.filter, this.props.facilityTZ || "", true, this.props.showAllRequests);
        }
    }

    renderErrorMessage() {
        const { hasError, errorCode, unassignedRequestHasError, unassignedRequestErrorCode, unassigned } = this.props;
        let message = '';
        if (
            errorCode === 'NotAuthorizedException' ||
            errorCode === 'UserNotFoundException' ||
            unassignedRequestErrorCode === 'NotAuthorizedException' ||
            unassignedRequestErrorCode === 'UserNotFoundException'
        ) {
            message = 'Incorrect username or password';
        } else {
            message = 'Error during Log In process.';
        }

        return (unassigned ? unassignedRequestHasError : hasError) ? (
            <div style={{ color: 'red' }}>{message}</div>
        ) : null;
    }

    goToSingle(id: string, req?: Request) {
        if (!this.state.isListView) {
            this.props.onViewUpdate && this.props.onViewUpdate(true); // First the parent's list view state is updated and then the child's state so that the rendering happens when parent's state is up to date and we use that state to fetch data for Upcoming Requests due column.
            this.setState({ isListView: true });
        } else {
            if (this.props.powerAndFacilityUsers) this.props.history.push({
                pathname: `/admin/request/${id}`,
                state: { similarRequestIds: req && req.similarRequestIds }
            });
            else this.props.history.push({
                pathname: `/request/${id}`,
                state: { similarRequestIds: req && req.similarRequestIds }
            });
        }
    }
    
    componentWillUnmount() {
        if (this.intervalId) window.clearInterval(this.intervalId);
        if (this.unsubscribeHandler) this.unsubscribeHandler();
    }
    handleView(value) {
        this.setState({
            isListView: value,
        });
    }
    handlePageChange(activePage) {
        console.log(activePage);
        this.props.setPageNo && this.props.setPageNo(activePage);
        this.setState({ ...this.state, pageNo: activePage });
    }
    handleFilter(startDate, endDate) {
        this.setState({
            filter: {
                startDate: startDate,
                endDate: endDate,
            },
        });
    }
    handleChangeStatusFilter(value: string) {
        this.setState({ statusFilter: value as string });
        switch (value) {
            case 'Accepted':
                this.props.updateAcceptedAndClosedRequestsFilters && this.props.updateAcceptedAndClosedRequestsFilters('accepted');
                break;
            case 'Closed':
                this.props.updateAcceptedAndClosedRequestsFilters && this.props.updateAcceptedAndClosedRequestsFilters('resolved');
                break;
            default:
                this.props.updateAcceptedAndClosedRequestsFilters && this.props.updateAcceptedAndClosedRequestsFilters('active');
                break;
        }
    }
    render() {
        return (
            <div className="Requests" style={{ width: (this.state.isListView && !this.props.modalView) ? '40vw' : '', borderRadius:'15px', marginBottom: "10px" }}>
                <Dimmer
                    active={
                        this.props.unassigned ? this.props.unassignedRequestIsFetching || false : this.props.isFetching
                    }
                    inverted
                >
                    <Loader
                        active={
                            this.props.unassigned
                                ? this.props.unassignedRequestIsFetching || false
                                : this.props.isFetching
                        }
                    />
                </Dimmer>
                <div className="request-header">
                    <h2>{this.props.requestTitle ? this.props.requestTitle : 'Requests'} ({this.props.unassigned ? this.props.unassignedRequestCount : this.props.count})</h2>
                    <div style={{ cursor: 'pointer', display: 'flex'}}>
                        {this.props.powerAndFacilityUsers && this.props.requestTitle === 'Accepted and Closed Requests' && (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Dropdown
                                    clearable
                                    style={{ minWidth: '80px', width: '100px', marginRight: '10px' }}
                                    placeholder={'Status'}
                                    closeOnEscape
                                    value={this.state.statusFilter}
                                    scrolling
                                    selection
                                    options={sortByKey(['Accepted', 'Closed'].map((item) => {
                                        return {
                                            key: item,
                                            value: item,
                                            text: item,
                                        };
                                    }))}
                                    onChange={(e, { value }) => this.handleChangeStatusFilter(value as string)}
                                />
                            </div>
                        )}
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}>
                            {
                                this.props.showSort && <div>
                                    <Dropdown options={this.props.requestSortOptions} selection value={this.props.sortDropdownValue} onChange={(e, { value }) => {
                                        if (value && this.props.setSortDropdownValue) {
                                            this.props.setSortDropdownValue(value as string);
                                        }
                                    }} />
                                </div>
                            }
                            <div className={this.state.isListView ? 'selected-view-icon' : 'view-icon'}>
                                <Icon name="list" size="large" onClick={() => {
                                    this.handleView(true);
                                    this.props.onViewUpdate && this.props.onViewUpdate(true);
                                }} />
                            </div>
                            <div className={this.state.isListView ? 'view-icon' : 'selected-view-icon'}>
                                <Icon
                                    name="calendar alternate outline"
                                    onClick={() => {
                                        this.handleView(false);
                                        this.props.onViewUpdate && this.props.onViewUpdate(false);
                                    }}
                                    size="large"
                                />
                            </div>
                            {!this.props.modalView ?
                                <div>
                                    <Icon name="expand" size="large" onClick={() => {
                                        if (this.props.powerAndFacilityUsers) {
                                            const basePath = "/admin/requests/";
                                            const queryParam = this.props.acceptedRequest ? "?expandAccepted=true" : this.props.closedRequest ? "?expandClosed=true" : this.props.isUpcomingRequestsDue ? "?expandUpcomingReqDue=true" : "?expandOpen=true";
                                            this.props.history.push(basePath + queryParam);
                                        }

                                        const isStaffUser = this.props.userId;

                                        if (isStaffUser) {
                                            const basePath = "/";
                                            // do the opposite for staff compared to facility admin because of the way props are passed.
                                            const queryParam = !this.props.unassigned ? "?expandAccClosed=true" : this.props.isUpcomingRequestsDue ? "?expandUpcomingReqDue=true" : "?expandOpen=true";
                                            this.props.history.push(basePath + queryParam);
                                        }
                                    }} />
                                </div> : <></>
                            }
                        </div>
                    </div>

                </div>
                {this.state.isListView ? (
                    (this.props.unassigned && this.props.unassignedRequests
                        ? this.props.unassignedRequests.length
                        : this.props.requests.length) !== 0 ? (
                        <div style={{ maxHeight: this.props.isUpcomingRequestsDue && !this.props.modalView ? '40vh' : '' }} className={!this.props.modalView ? "request-container" : ""}>
                            <RequestsList
                                powerAndFacilityUsers={this.props.powerAndFacilityUsers}
                                requests={
                                    this.props.unassigned ? this.props.unassignedRequests || [] : this.props.requests
                                }
                                handleClick={this.goToSingle.bind(this)}
                                unassigned={this.props.unassigned}
                                refresh={this.props.refresh || (() => { })}
                                sortBy={this.props.sortDropdownValue}
                                orderIdColorDict={this.props.orderIdColorDict || {}}
                            />
                            {this.props.powerAndFacilityUsers ? (
                                <LoadMore
                                    isLoading={
                                        this.props.unassigned
                                            ? this.props.unassignedRequestIsFetching || false
                                            : this.props.isFetching
                                    }
                                    hasMore={this.props.hasMorePages || false}
                                    next={() => this.handlePageChange(this.props.page + 1)}
                                    skipMargin={true}
                                />
                            ) : (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Pagination
                                        boundaryRange={0}
                                        activePage={this.props.powerAndFacilityUsers ? this.props.page : this.state.pageNo}
                                        ellipsisItem={null}
                                        firstItem={null}
                                        lastItem={null}
                                        onPageChange={(e, { activePage }) => this.handlePageChange(activePage)}
                                        totalPages={
                                            this.props.unassigned
                                                ? Math.ceil(
                                                    (this.props.unassignedRequestCount || 1) /
                                                    (this.props.unassignedRequestLimit || 1),
                                                )
                                                : Math.ceil(this.props.count / this.props.limit)
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="no-requests">
                            {this.props.emptyMessage ? this.props.emptyMessage : 'No Requests'}
                        </div>
                    )
                ) : (
                    <div className="calender-container">
                        <RequestCalenderView
                            requests={this.props.unassigned ? this.props.unassignedRequests || [] : this.props.requests}
                            handleClick={this.goToSingle.bind(this)}
                            unassigned={this.props.unassigned}
                            changeFilter={() => {}}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default Requests
