import React, { useState, useEffect } from 'react'
// import { Tab } from 'semantic-ui-react'
// import Reports from './Reports'
import { withRouter } from 'react-router'
// import Requests from '../../../components/Dashboard/Requests'
// import Basics from '../../../components/Dashboard/Basics'
import AllRequests from '../../../components/Dashboard/AllRequests'

const RequestsHome = (props) => {
    // console.log('Home props', props)
    // console.log(' Requests home')

    // const panes = [
    //     {
    //         menuItem: 'Requests',
    //         render: () => (
    //             <Tab.Pane attached={false}>
    //                 <Requests profile={props.profile} />
    //             </Tab.Pane>
    //         ),
    //     },
    //     {
    //         menuItem: 'Standard',
    //         render: () => (
    //             <Tab.Pane attached={false}>  
    //                 <Basics />
    //             </Tab.Pane>
    //         ),
    //     },
    // ]

    // const panes = [
    //     {
    //         menuItem: 'Requests',
    //         render: () => (
    //             <Tab.Pane attached={false}>
    //                 <AllRequests profile={props.profile} />
    //             </Tab.Pane>
    //         ),
    //     },
    // ]

    return (
        <div className="DashboardHome">
            <AllRequests profile={props.profile} />
            {/* <Tab menu={{ secondary: true, pointing: true }} panes={panes} /> */}
        </div>
    )
}

export default withRouter(RequestsHome)
