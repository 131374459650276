import API from './API'
import { isFacilityAdmin, isJustPowerUser, isStaff } from './Permissions'
import { User, UserRole, UserProfile, Department, Identifiable } from '../types'
import { fetchAllPaginatedData } from '../util'
import { PageSort } from './Assets'

function formatPhone(phone: string) {
    const p = phone.replace(/\(|\)|\s|-/g, '')
    return `+${p}`
}

export async function fetchActiveUsers(): Promise<User[]> { //backend limits this to 100 users
    const res = await API.lambdaGet('/users/list');
    const users = res.Result as User[];
    return users.filter(u => u.IsActive === 1);
}

export async function fetchOneUser(id: string): Promise<User> {
    const res = await API.lambdaGet(`/users/get?_id=${id}`)
    return res as User
}

export async function fetchAllStaffUsers(): Promise<User[]> {
    // fetch paginated staff users for this facility (should be updated to support superadmin)
    const params = {
        Filter: {},
    };
    const fetchUserApiCall = async (params: any) => {
        return await API.lambdaPost('/users/list', params);
    };
    const allUsers: User[] = await fetchAllPaginatedData(fetchUserApiCall, params);
    if (!allUsers) throw new Error('Cannot fetch notepad entries');
    return allUsers.filter((user) => isStaff(user) && user.IsActive === 1);
}

export async function fetchAllActiveUsers(): Promise<User[]> {
    // fetch paginated active users for this facility (should be updated to support superadmin)
    const params = {
        Filter: {},
    };
    const fetchUserApiCall = async (params: any) => {
        return await API.lambdaPost('/users/list', params);
    };
    const allUsers: User[] = await fetchAllPaginatedData(fetchUserApiCall, params);
    if (!allUsers) throw new Error('Cannot fetch notepad entries');
    return allUsers.filter((user) => user.IsActive === 1);
}

interface usersWithTotRecords {
    users: User[]
    TotRecords: number
    recievedResponse?: number
}

export async function fetchStaffUsers(page_no = 1, search, page_size = 50, showInactiveUsers = false): Promise<usersWithTotRecords> {
    console.log("search", search)

    const res = await API.lambdaPost('/users/list', {
        Filter: showInactiveUsers ? { showInactiveUsers } : { IsActive: 1 },
        Search: search,
        QueryOptions: {
            page_size: page_size, page_no,
            sort: [{ _id: 'asc' }],

        }
    });
    const users = res.Result as User[];
    let staffs: User[] = [];
    if (showInactiveUsers) {
        staffs = users.filter(user => isStaff(user));
    } else {
        staffs = users.filter(user => isStaff(user) && user.IsActive === 1);
    }
    const recievedResponse = Number(((page_no - 1) * page_size) + users.length)
    return { users: staffs, TotRecords: res.TotRecords, recievedResponse: recievedResponse };
}

export async function fetchStaffRoleUsers(): Promise<User[]> {
    const users = await API.lambdaGet('/users/list');

    const staffUsers = users.Result.filter(({ Roles }: { Roles: UserRole[] }) => {
        return Roles.filter((Role: UserRole) => {
            return Role.Name = 'Staff';
        }).length ? true : false;
    });

    return staffUsers;
}

export async function fetchPowerUsers(page_no = 1, search, showInactiveUsers = false): Promise<usersWithTotRecords> {
    console.log("S", search)
    const res = await API.lambdaPost('/users/list',
        {
            Filter: showInactiveUsers ? { showInactiveUsers } : { IsActive: 1 },
            Search: search,
            QueryOptions: {
                page_size: 50, page_no,
                sort: [{ _id: 'asc' }],

            }
        })
    const users = res.Result as User[]
    let powerUsers: User[] = [];
    if (showInactiveUsers) {
       powerUsers = users.filter(user => isJustPowerUser(user));
    } else {
        powerUsers = users.filter(user => isJustPowerUser(user) && user.IsActive === 1);
    }
    return { users: powerUsers, TotRecords: res.TotRecords };
}

export async function fetchAdminUsers(page_no = 1, search, showInactiveUsers = false): Promise<usersWithTotRecords> {
    console.log("s", search)
    const res = await API.lambdaPost('/users/list',
        {
            Filter: showInactiveUsers ? { showInactiveUsers } : { IsActive: 1 },
            Search: search,
            QueryOptions: {
                page_size: 50, page_no,
                sort: [{ _id: 'asc' }],

            }
        })
    const users = res.Result as User[];
    let admins: User[] = [];
    if (showInactiveUsers) {
        admins = users.filter(user => isFacilityAdmin(user));
    } else {
        admins = users.filter(user => isFacilityAdmin(user) && user.IsActive === 1);
    }
    return { users: admins, TotRecords: res.TotRecords };
}

export async function fetchUserRoles(): Promise<UserRole[]> {
    const res = await API.lambdaGet('/roles/list')
    const roles = res.Result as UserRole[]
    return roles
}

export async function fetchAssignableUserRoles(): Promise<UserRole[]> {
    const roles = await fetchUserRoles()
    return roles.filter(role => (role.Name !== 'System Admin' && role.IsActive === 1 && role._id !== '5d4069f281d16c1f7077b4d9')) // "5d4069f281d16c1f7077b4d9" is the id of "Arduino" role
}

export async function addUserToRole(userId: string, roleId: string): Promise<any> {
    const res = await API.lambdaPost('/users/roles/add', {
        User: userId,
        Role: roleId,
    })
    return res
}

export async function removeUserFromRole(userId: string, roleId: string): Promise<any> {
    const res = await API.lambdaPost('/users/roles/remove', {
        User: userId,
        Role: roleId,
    })
    return res
}

export async function addUserToDepartment(userId: string, departmentId: string): Promise<any> {
    const res = await API.lambdaPost('/users/departments/add', {
        User: userId,
        Department: departmentId,
    })
    return res
}

export async function removeUserFromDepartment(userId: string, departmentId: string): Promise<any> {
    const res = await API.lambdaPost('/users/departments/remove', {
        User: userId,
        Department: departmentId,
    })
    return res
}

export async function createUserWithDepartments(
    userData: Partial<User>,
    departments: Department[],
    roles: UserRole[],
): Promise<any> {
    const dataClone = { ...userData }
    if (dataClone.Departments) delete dataClone.Departments
    if (dataClone.Roles) delete dataClone.Roles
    // "Phone number formatting for 'Phone' and 'Cell' is already done, no need for below lines of code."
    // if (dataClone.Phone) dataClone.Phone = formatPhone(dataClone.Phone)
    // if (dataClone.Cell) dataClone.Cell = formatPhone(dataClone.Cell)

    const createdId = await API.lambdaPost('/users/add', {
        ...dataClone,
        IsActive: 1,
    })

    const roleAddPromises = roles.map(role => addUserToRole(createdId, role._id))

    const departmentAddPromises = departments.map(d => addUserToDepartment(createdId, d._id))

    await Promise.all([...departmentAddPromises, ...roleAddPromises])

    const newUser = await fetchOneUser(createdId)
    return newUser
}

export async function upsertGroupsToUser(userId: string, groupIds: string[]): Promise<any> {
    try {
        const res = await API.lambdaPost('/users/groups/upsert', { userId, groupIds });
        return res;
    } catch (error) {
        console.error("error in upsertGroupsToUser", error);
        throw error;
    }
}

export async function updateUser(updated: Partial<User> & Identifiable, oldUser: User): Promise<any> {
    const existingDepIds = oldUser && oldUser.Departments && Array.isArray(oldUser.Departments) && oldUser.Departments.map(d => d._id) || []
    const newDepIds = (updated.Departments && updated.Departments.map(d => d._id)) || []
    const depsToAdd = newDepIds.filter(d => !existingDepIds.includes(d))
    const depsToRemove = existingDepIds.filter(d => !newDepIds.includes(d))

    const existingRoleIds = oldUser.Roles.map(role => role._id)
    const newRoleIds = (updated.Roles && updated.Roles.map(r => r._id)) || []
    const rolesToAdd = newRoleIds.filter(r => !existingRoleIds.includes(r))
    const rolesToRemove = existingRoleIds.filter(r => !newRoleIds.includes(r))

    const dataClone = { ...updated };
    // Remove Groups key from dataClone before API call
    delete dataClone.Groups;

    await API.lambdaPost('/users/update', dataClone);

    const addDepsPromises = depsToAdd.map(d => addUserToDepartment(updated._id, d))
    const removeDepsPromises = depsToRemove.map(d => removeUserFromDepartment(updated._id, d))
    const addRolesPromises = rolesToAdd.map(r => addUserToRole(updated._id, r))
    const removeRolesPromises = rolesToRemove.map(r => removeUserFromRole(updated._id, r))

    await Promise.all([...addDepsPromises, ...removeDepsPromises, ...addRolesPromises, ...removeRolesPromises])

    const isGroupDataChanged = checkGroupsChanged(oldUser.Groups, updated.Groups);

    if (isGroupDataChanged) {
        const groupIds = updated && updated.Groups && updated.Groups.map(group => group._id);
        if (groupIds) { // sometimes groupIds is a empty string but that also gets evaluated as true
            await upsertGroupsToUser(updated._id, groupIds);
        }
    }
}

function checkGroupsChanged(oldGroups, newGroups) {
    // Extract group IDs from old and new groups
    const oldGroupIds = oldGroups && oldGroups.map(group => String(group._id)) || [];
    const newGroupIds = newGroups && newGroups.map(group => String(group._id)) || [];

    // Determine if there are any changes
    const isChanged = newGroupIds.some(newId => !oldGroupIds.includes(newId)) ||
        oldGroupIds.some(oldId => !newGroupIds.includes(oldId));
    return isChanged;
}

export async function fetchProfile(): Promise<UserProfile> {
    const res = await API.lambdaGet('/users/profile/get')
    return res as UserProfile
}

export async function updateProfile(
    newProfile: Pick<UserProfile, 'FirstName' | 'LastName' | 'Phone' | 'Cell' | 'Icon' | 'AnnouncementAlerts' | 'EmailAnnouncementAlert' | "Groups">,
): Promise<UserProfile> {
    const dataClone = { ...newProfile }
    // "Phone number formatting for 'Phone' and 'Cell' is already done, no need for below lines of code."
    // if (dataClone.Phone) dataClone.Phone = formatPhone(dataClone.Phone)
    // if (dataClone.Cell) dataClone.Cell = formatPhone(dataClone.Cell)
    await API.lambdaPost('/users/profile/update', dataClone)
    const updated = await fetchProfile()
    return updated
}

export async function deleteUser(userId: string) {
    await API.lambdaPost('/users/update', {
        _id: userId,
        IsActive: 0,
    })
}

export async function reactivateUser(userId: string) {
    await API.lambdaPost('/users/update', {
        _id: userId,
        IsActive: 1,
    })
}


export async function setNewPassword(userId: string, password: string) {
    await API.lambdaPost('/users/setnewpassword', {
        userId,
        password,
    })

}


export async function forgotPassword(username: string) {
    return await API.lambdaPost("/forgot-password", {
        identifier: username
    })
}

export async function confirmPassword(username: string, verificationCode: string, newPassword: string) {
    return await API.lambdaPost("/confirm-password", {
        identifier: username,
        verificationCode,
        newPassword
    })

}

export async function fetchDepartmentStaffs(filters = {}): Promise<usersWithTotRecords> {
    const params = {
        Filter: filters,
    };
    const res = await API.lambdaPost('/users/departments/listUsers', params);
    if (!res || !res.Result) return { users: [], TotRecords: 0 };
    return { users: res.Result as User[], TotRecords: res.TotRecords };
}

export async function fetchCommunityLiaisons({ pagingSorting }) {
    const res = await API.lambdaPost('/users/list', {
        Filter: {
            IsActive: 1,
            CommunityLiaison: true
        },
        pagingSorting
    });
    const users = res.Result as User[];
    return { Result: users, TotRecords: res.TotRecords};
}

export async function resendUserPassword(userId: string) {
    try {
        const response = await API.lambdaPost('/users/password/resend', { userId });
        return response;
    } catch (error) {
        throw new Error(error instanceof Error ? error.message : "Failed to resend password for user");
    }
}