import React, { useEffect, useState } from 'react';
import { List, Segment, Button, Dimmer, Loader, Popup, Tab, TabProps } from 'semantic-ui-react';
import { ListPaginatedResidentCallHistory } from '../../services/CallHistory';
import { sendToast } from '../../util';
import moment from 'moment';
import { CallHistory } from '../../types/CallHistory';

interface MissedCallsComponentProps {
	residentId?: string | null;
	staffId?: string | null;
	showMissed?: boolean;
	setSelectCallLog?: Function;
	selectedCallLog?: CallHistory;
	setSelectedResident?: Function;
}

const CallList = ({
	residentId,
	staffId,
	showMissed,
	setSelectCallLog,
	selectedCallLog,
	setSelectedResident
}: MissedCallsComponentProps) => {
	const [activeTab, setActiveTab] = useState<string>(showMissed ? 'Missed' : 'All');
	const [allCalls, setAllCalls] = useState<CallHistory[]>([]);
	const [missedCalls, setMissedCalls] = useState<CallHistory[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [pageNo, setPageNo] = useState<number>(1);
	const [refresh, setRefresh] = useState<boolean>(false);
	const pageSize = 25;
	const userId = staffId || residentId;

	useEffect(() => {
		fetchCalls();
	}, [userId, activeTab, pageNo, refresh]);

	useEffect(() => {
		if (selectedCallLog) {
			const updateCallList = (calls: CallHistory[]) => {
				let updated = false;
				const updatedCalls = calls.map((call) => {
					if (call._id === selectedCallLog._id) {
						updated = true;
						return selectedCallLog;
					}
					return call;
				});
				return updated ? updatedCalls : calls;
			};
			setMissedCalls((prevMissedCalls) => updateCallList(prevMissedCalls));
			setAllCalls((prevAllCalls) => updateCallList(prevAllCalls));
		}
	}, [selectedCallLog]);

	const fetchCalls = async () => {
		if (!userId) return;
		try {
			setLoading(true);

			const res = await ListPaginatedResidentCallHistory(
				{
					...{ residentId: residentId },
					...{ staffId: staffId },
					...(activeTab === 'Missed' && { status: 'declined/missed' }),
				},
				pageNo,
				pageSize
			);
			const totalRecords = res.TotRecords;
			setTotalPages(Math.ceil(totalRecords / pageSize));

			if (activeTab === 'Missed') {
				setMissedCalls((prev) => [...prev, ...res.Result]);
			} else {
				setAllCalls((prev) => [...prev, ...res.Result]);
			}
		} catch (error) {
			console.error(error);
			sendToast('error', `Failed to fetch ${activeTab.toLowerCase()} call history`);
		} finally {
			setLoading(false);
		}
	};

	const handleLoadMore = async () => {
		setPageNo((prevPage) => prevPage + 1);
	};

	const renderCalls = (calls: CallHistory[]) => (
		<List divided>
			{calls.map((call, index) => (
				<Popup
					content={call.notes || "-"}
					trigger={
						<List.Item
							key={index}
							style={{
								padding: '5px 0',
								cursor: 'pointer',
							}}
							onClick={() => {
								if (setSelectCallLog) {
									setSelectCallLog(call);
								}
								if (setSelectedResident) {
									const residentId = call.residentId;
									setSelectedResident(residentId);
								}
							}}
						>
							<List.Content>
								<List.Description style={{ fontSize: '13px', }}>
									<span style={{ fontSize: '12.4px', color: (selectedCallLog && (String(call._id) === String(selectedCallLog._id))) ? '#d9edf7' : call.status === "completed" ? "#69db7c" : '#ff6b6b' }}>
										{moment(call.startTimestamp).format('MM-DD h:mm A') + ' - '}
									</span>
									{staffId
										? call.direction === 'outbound'
											? call.caller
											: call.reciever
										: call.direction === 'outbound'
											? call.reciever
											: call.caller}
								</List.Description>
							</List.Content>
						</List.Item>
					}
				/>
			))}
		</List>
	);

	const panes = [
		{
			menuItem: { key: 'missed', content: 'MISSED', style: { flex: 1, textAlign: 'center', justifyContent: 'center' } },
		},
		{
			menuItem: { key: 'all', content: 'ALL', style: { flex: 1, textAlign: 'center', justifyContent: 'center' } },
		},
	];

	const handleTabChange = (e: React.MouseEvent<HTMLDivElement>, { activeIndex }: TabProps) => {
		if (activeIndex === 0) {
			setPageNo(1);
			setAllCalls([]);
			setMissedCalls([]);
			setActiveTab('Missed');
			setRefresh(!refresh);
		} else {
			setPageNo(1);
			setMissedCalls([]);
			setAllCalls([]);
			setActiveTab('All');
			setRefresh(!refresh);
		}
	}

	return (
		<div
			style={{
				marginLeft: '5px',
				background: '#ffffff',
				border: '1px solid #ccc',
				borderRadius: '5px',
				padding: '5px',
				height: '20vw',
				display: 'flex',
				flexDirection: 'column',
				fontSize: '12px',
			}}
		>
			<Dimmer active={loading} inverted>
				<Loader size="small">Loading</Loader>
			</Dimmer>
			{showMissed && (
				<Segment
					style={{
						display: 'flex',
						width: '100%',
						border: 'none',
						padding: '0',
						margin: '0',
						textAlign: 'center',
					}}
				>
					<Tab
						style={{ width: '100%'}}
						menu={{
							attached: true,
							style: {
								display: 'flex',
								fontSize: '12px',
							},
						}}
						panes={panes}
						onTabChange={handleTabChange}
					/>
				</Segment>
			)}
			<Segment
				basic
				style={{
					flex: 1,
					overflowY: 'auto',
					padding: '0',
				}}
			>
				{activeTab === 'Missed' ? renderCalls(missedCalls) : renderCalls(allCalls)}
				{pageNo < totalPages && (
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Button
							size="tiny"
							fluid
							style={{ padding: '5px', width: '50%' }}
							onClick={handleLoadMore}
						>
							Load More
						</Button>
					</div>
				)}
			</Segment>
		</div>
	);
};

export default CallList;