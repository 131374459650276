import API from './API'
import { Notification } from '../types'
import { fetchAllPaginatedData } from '../util';

export async function fetchFilteredNotifications(filter: object): Promise<Notification[]> {
    filter = { ...filter, showServiceAnnouncements: true }
    const params = { filter };
    const sorting = undefined;
    const page_size = 100;
    // fetch paginated notifications
    const notificationsApiCall = async (params: any) => {
        return await API.lambdaPost('/facilities/notifications/list', params);
    };
    const allNotifications = await fetchAllPaginatedData(notificationsApiCall, params, sorting, page_size);
    return allNotifications as Notification[];
}

//sends announcements
export async function sendAnnouncement(
    message: string,
    roomIds: string[],
    triggerDateTimeString?: string,
    recurrence?: string,
    sendMail?: boolean,
    sendSms?: boolean,
    sendStaff?: boolean,
    dismissalTimeOffsetMinutes?: number,
    sendNotification?: boolean,
    dontSaveAnnouncement?: boolean
): Promise<any> {
    console.log('Announcement : ', message, ' ', roomIds)
    const payload = {
        Message: message,
        RoomIds: roomIds,
        triggerDateTimeString,
        recurrence,
        sendMail,
        sendSms,
        sendStaff,
        dismissalTimeOffsetMinutes,
        sendNotification,
        dontSaveAnnouncement,
        pushToQueue : true,
    };

    if(!payload.recurrence) {
        delete payload.recurrence;
        delete payload.triggerDateTimeString;
    }
    return await API.lambdaPost('/facilities/notifications/send', payload);
}

export async function deleteAnnouncement(_id: string): Promise<any> {
    return await API.lambdaPost('/facilities/notifications/deleteFutureAnnouncement', {
        _id,
    })
}

export async function sendPersistentVisualAlert(
    message: string,
    roomIds: string[],
    dismissalTimeOffsetMinutes?: number,
    triggerTimestampISO?: string,
): Promise<any> {
    return await API.lambdaPost('/facilities/notifications/persistentVisualAlert', {
        Message: message,
        RoomIds: roomIds,
        dismissalTimeOffsetMinutes,
        triggerTimestampISO,
    })
}

export async function fetchAllDeviceNotifications({ startDateTimeISO, endDateTimeISO }): Promise<Notification[]> {
    const params = {
        Filter: {
            startDateTimeISO,
            endDateTimeISO
        }
    };
    const sorting = [{ _id: 'asc' }];
    const page_size = 100;
    // fetch paginated notifications
    const fetchNotificationsAPICall = async (params: any) => {
        return await API.lambdaPost('/facilities/notifications/deviceNotificationsList', params);
    };
    const allNotifications = await fetchAllPaginatedData(fetchNotificationsAPICall, params, sorting, page_size);
    return allNotifications as Notification[];
}

export async function sendAlexaDeviceNotification(
    message: string,
    subject: string,
    roomIds: string[],
    startDate?: string,
    endDate?: string,
): Promise<any> {
    return await API.lambdaPost('/facilities/notifications/deviceNotification', {
        Message: message,
        Subject: subject,
        RoomIds: roomIds,
        triggerTimestampISO: startDate,
        EndDate: endDate,
        pushToQueue: true,
    })
}

export async function sendAlexaProactiveSuggestion(
    subject: string,
    message: string,
    roomIds: string[],
    startDate?: string,
    endDate?: string,
): Promise<any> {
    console.log('sending proactive', subject, message, roomIds, endDate, startDate)
    return await API.lambdaPost('/facilities/notifications/proactiveSuggestion', {
        Subject: subject,
        Message: message,
        RoomIds: roomIds,
        triggerTimestampISO: startDate,
        EndDate: endDate,
    })
}

export async function editFutureAnnouncement(data): Promise<any> {
    return await API.lambdaPost('/facilities/notifications/editFutureAnnouncements', {
        _id: data._id,
        Message: data.message,
        RoomIds: data.roomIds,
        triggerDateTimeString: data.triggerDateTimeString,
        notificationId: data.notificationId,
        recurrence: data.recurrence,
        dismissalTimeOffsetMinutes: data.dismissalTimeOffsetMinutes
    })
}

export async function deleteDeviceNotification(_id: string): Promise<any> {
    return await API.lambdaPost('/facilities/notifications/deleteFutureNotification', {
        _id,
    })
}

export async function editDeviceNotification(data: object): Promise<any> {
    return await API.lambdaPost('/facilities/notifications/editFutureNotification', data)
}
