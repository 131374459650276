import React from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import { Image, Button, Icon, Dimmer, Loader, Message } from 'semantic-ui-react'
import { fetchOneRequest, acceptRequest, closeRequest, setRequestSentiment, unAcceptRequest, closeAllRequests } from '../../../../services/Requests'
import { UserProfile } from '../../../../types'
import './style.less'
import { AppState } from '../../../../reducers'
import { connect } from 'react-redux'
import { distanceInWordsToNowWithTimezone, formatDateWithTZ } from '../../../../util/timezone'
import IconList from '../../../AdminPanel/Requests/CategoryIconList/IconList';
import { RequestInstance } from '../../../../types/RequestInstance';
import moment from 'moment';
import CreateRequestModal from '../../../../components/Requests/CreateRequestModal';
import momentTimezone from 'moment-timezone';
interface RouteInfo {
    id: string
}
interface Props extends RouteComponentProps<RouteInfo> {
    profile?: UserProfile | null
}

interface State {
    isFetching: boolean
    hasError: boolean
    error: string | null
    request: RequestInstance | null
}

class SingleRequest extends React.Component<Props, State> {
    id: string
    constructor(props: Props) {
        super(props)
        this.id = props.match.params.id
        this.state = {
            isFetching: false,
            hasError: false,
            error: null,
            request: null,
        }
    }

    async componentDidMount() {
        await this.fetchRequestDetails();
    }

    async fetchRequestDetails() {
        this.setState({
            isFetching: true,
            hasError: false,
            error: null,
        })
        try {
            const res = await fetchOneRequest(this.id)
            this.setState({
                isFetching: false,
                request: res,
                hasError: res ? false : true,
                error: res ? null : `Could not find request ${this.id}`,
            })
        } catch (e) {
            this.setState({
                isFetching: false,
                hasError: true,
                error: e.message,
            })
        }
    }

    goHome() {
        this.props.history.push('/')
    }

    formatAcceptedTime(time) {
        if (!time) return undefined;

        const facilityTimezone = this.props.profile && this.props.profile.FacilityTimeZone || "";

        const formatString = moment(new Date(time)).year() > moment().year()
            ? 'MMM DD, YYYY, hh:mm A'
            : 'MMM DD, hh:mm A';

        return formatDateWithTZ(time, formatString, facilityTimezone);
    }

    formatSvcRequestTime = (time: number | Date | undefined) => {
        // collects time in timestamp and returns a string in the format "at 12:00 PM on Mar 19, 2021"
        if (!time) return "";
        const facilityTimezone = this.props.profile && this.props.profile.FacilityTimeZone || "";
        if (!facilityTimezone) {
            return "";
        }
        const formatString = 'h:mm A on MMM D, YYYY';
        const formattedTime = 'at ' + momentTimezone.tz(time, facilityTimezone).format(formatString);
        return formattedTime;
    }

    async handleAccept() {
        this.setState({
            isFetching: true,
            hasError: false,
            error: null,
        })
        try {
            const newRequest = await acceptRequest(this.id)
            this.setState({
                request: newRequest,
                isFetching: false,
                hasError: false,
            })
            this.goHome()
        } catch (e) {
            console.log(e)
            this.setState({
                request: null,
                isFetching: false,
                hasError: true,
                error: e.message,
            })
        }
    }

    async handleClose() {
        this.setState({
            isFetching: true,
            hasError: false,
            error: null,
        });
        if (!(this.props.location.state && this.props.location.state.similarRequestIds) ) {
            try {
                const newRequest = await closeRequest(this.id);
                this.setState({
                    request: newRequest,
                    isFetching: false,
                    hasError: false,
                });
                this.props.history.push('/');
            } catch (e) {
                this.setState({
                    isFetching: false,
                    hasError: true,
                    error: e.message,
                });
            }
        } 
        if (this.props.location.state &&
            this.props.location.state.similarRequestIds &&
            Array.isArray(this.props.location.state.similarRequestIds) &&
            this.props.location.state.similarRequestIds.length > 0
        ) {
            try {
                const ids = this.props.location.state.similarRequestIds;
                await closeAllRequests(ids);
                this.setState({
                    isFetching: false,
                    hasError: false,
                });
                this.props.history.push('/');
            } catch (e) {
                this.setState({
                    isFetching: false,
                    hasError: true,
                    error: e.message,
                });
            }
        }
    }

    async handleUnAccept() {
        this.setState({
            isFetching: true,
            hasError: false,
            error: null,
        })
        try {
            const newRequest = await unAcceptRequest(this.id)
            this.setState({
                request: newRequest,
                isFetching: false,
            })
            this.goHome()
        } catch (e) {
            console.log(e)
            this.setState({
                request: null,
                isFetching: false,
                hasError: true,
                error: e.message,
            })
        }
    }

    async setSentiment(sentiment: number) {
        if (!this.state.request) return
        this.setState({
            isFetching: true,
            hasError: false,
            error: null,
            request: {
                ...this.state.request,
                Sentiment: sentiment,
            },
        })
        try {
            const newRequest = await setRequestSentiment(this.id, sentiment)
            this.setState({
                request: newRequest,
                isFetching: false,
                hasError: false,
            })
        } catch (e) {
            this.setState({
                isFetching: false,
                hasError: true,
                error: e.message,
            })
        }
    }

    renderFaces(request: RequestInstance) {
        const isAccepted = request.Status === 'Accepted'
        const isClosed = request.Status === 'Closed'
        const faces = [
            <Icon
                key={1}
                className="face"
                onClick={() => this.setSentiment(1)}
                name="frown outline"
                color="red"
                size={request.Sentiment ? (request.Sentiment === 1 ? 'huge' : 'big') : 'big'}
            />,
            <Icon
                key={2}
                className="face"
                onClick={() => this.setSentiment(2)}
                name="frown outline"
                color="orange"
                size={request.Sentiment ? (request.Sentiment === 2 ? 'huge' : 'big') : 'big'}
            />,
            <Icon
                key={3}
                className="face"
                onClick={() => this.setSentiment(3)}
                name="meh outline"
                color="yellow"
                size={request.Sentiment ? (request.Sentiment === 3 ? 'huge' : 'big') : 'big'}
            />,
            <Icon
                key={4}
                className="face"
                onClick={() => this.setSentiment(4)}
                name="smile outline"
                color="olive"
                size={request.Sentiment ? (request.Sentiment === 4 ? 'huge' : 'big') : 'big'}
            />,
            <Icon
                key={5}
                className="face"
                onClick={() => this.setSentiment(5)}
                name="smile outline"
                color="green"
                size={request.Sentiment ? (request.Sentiment === 5 ? 'huge' : 'big') : 'big'}
            />,
        ]
        const faceButtons = <div className="faces">{faces}</div>
        if (isAccepted && !request.Sentiment && !isClosed) return faceButtons
        if (isAccepted && request.Sentiment) return faceButtons
        return null
    }

    renderRequest(request: RequestInstance) {
        const isOpen = request.Status === 'Open'
        const isClosed = request.Status === 'Closed'
        const isAccepted = request.Status === 'Accepted'

        const closeBtn = (
            <Button
                basic
                disabled={isClosed || this.state.isFetching}
                loading={this.state.isFetching}
                primary
                icon="play"
                content="Close"
                id="actionButton"
                onClick={this.handleClose.bind(this)}
            />
        )
        const acceptBtn = (
            <Button
                basic
                primary
                disabled={this.state.isFetching}
                loading={this.state.isFetching}
                icon="add"
                onClick={this.handleAccept.bind(this)}
                content="Accept"
                id="actionButton"
            />
        )

        const UnAcceptBtn = (
            <Button
                basic
                primary
                disabled={this.state.isFetching}
                loading={this.state.isFetching}
                onClick={this.handleUnAccept.bind(this)}
                content="Unaccept"
                id="actionButton"
                style={{ marginLeft: "5px" }}
            />
        )
        
        const avatarUrl = request.Registrant_Image || `${process.env.PUBLIC_URL}/avatar_placeholder.svg`
        const reqTypeIcon = request.Department_Icon || `${process.env.PUBLIC_URL}/request_type_placeholder.png`

        const generateReqTimeDiffString = (request) => {
            const isScheduled = request.ScheduledTime && request.ScheduledTime.startDate
            const facilityTZ = this.props.profile && this.props.profile.FacilityTimeZone || "";

            if(isScheduled) {
                return `Scheduled ${distanceInWordsToNowWithTimezone(request.ScheduledTime.startDate, facilityTZ)}`
            } else {
                return `Created ${distanceInWordsToNowWithTimezone(request.RequestedTime, facilityTZ)}`
            }
        }
        const renderServiceRequestDetails = (request: RequestInstance) => {
            return (
                <>
                    <div className="header" style={{ flexDirection: 'column' }}>
                        <CreateRequestModal
                            facilityId={this.props.profile && this.props.profile.Facility || ""}
                            request={request}
                            refresh={this.goHome.bind(this)}
                            cancelClickHandler={this.goHome.bind(this)}
                            parentLoading={this.state.isFetching}
                            handleOnClose={this.handleClose.bind(this)}
                            handleOnAccept={this.handleAccept.bind(this)}
                        />
                    </div>
                </>
            );
        };
        const renderStandaloneRequestDetails = (request: RequestInstance) => {
            return (
                <>
                    <div className="header">
                        <div className="image-container icon-container">
                            {request['Category'] ? (
                                <IconList size="large" name={request['Category'] || 'Other'} />
                            ) : (
                                <Image circular size="tiny" src={reqTypeIcon} />
                            )}
                        </div>
                        <div className="short-details">
                            <div className="req-info-text">
                                {request.Registrant_FirstName + ' ' + request.Registrant_LastName}
                            </div>
                            <div className="req-info-text">Room {request.Unit && request.Unit_Name}</div>
                            {request.ServiceName && <div className="req-info-text">Service: {request.ServiceName}</div>}
                            <div className="req-info-text">{request.Name}</div>
                            {request.Description && (
                                <div className="req-info-text">Description: {request.Description}</div>
                            )}
                            {/* <div className="req-info-text">
                                Created at {this.formatAcceptedTime(request.DateAdded)}, <br />
                                <li className="req-info-text">{generateReqTimeDiffString(request)}</li>
                            </div> */}
                        </div>
                        <div className="image-container">
                            <Image circular size="tiny" src={avatarUrl} />
                        </div>
                    </div>
                    {request.Details && <div className="req-info-text">{request.Details}</div>}
                    {request.Registrant_Notes && <div className="req-info-text">{request.Registrant_Notes}</div>}
                    {/* <h3 className="primary">Request Id: {request._id}</h3>
                    {request.AcceptedTime && (
                        <div className="req-info-text">
                            <h3 className="primary">Time Accepted: {this.formatAcceptedTime(request.AcceptedTime)}</h3>
                            {request.ClosedTime && (
                                <h3 className="primary">Time Closed: {this.formatAcceptedTime(request.ClosedTime)}</h3>
                            )}
                        </div>
                    )} */}
                    <Message size='small' style={{ textAlign: 'left' }}>
                        <strong>Request Id: {request && request._id}</strong>
                        <br />
                        <br />
                        <strong>
                            Created {this.formatSvcRequestTime(request && request.DateAdded)} by{' '} {request && (
                                ((request.Registrant_FirstName || '') + ' ' + (request.Registrant_LastName || '')
                                ).trim()
                            )}
                        </strong>
                        <br />
                        {request && request.AcceptedTime && (
                            <strong>
                                <br />
                                Accepted by: {(request && request.AcceptedByName) || ''}{' '}
                                {this.formatSvcRequestTime(request && request.AcceptedTime)}
                                <br />
                            </strong>
                        )}
                        {request && request.ClosedTime && (
                            <strong>
                                <br />
                                Closed by: {(request && request.ClosedByName) || ''}{' '}
                                {this.formatSvcRequestTime(request && request.ClosedTime)}
                                <br />
                            </strong>
                        )}
                    </Message>
                    {request.ImageUrl && (
                        <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                            <Image size="small" src={request.ImageUrl} />
                        </div>
                    )}
                    {request.ServiceAddOns && request.ServiceAddOns.length > 0 && (
                        <div className="req-info-text">
                            Service Add-ons:
                            {request.ServiceAddOns.map((addOn) => {
                                return (
                                    <li style={{ margin: '10px 0px' }} key={addOn.id}>
                                        {addOn.itemName}
                                    </li>
                                );
                            })}
                        </div>
                    )}
                </>
            );
        };

        return (
            <div className="request-view">
                {
                    (request.OriginalServiceId || request.OriginalServiceInstanceId) ? renderServiceRequestDetails(request) : renderStandaloneRequestDetails(request)
                }
                {(!request.OriginalServiceId || !request.OriginalServiceInstanceId) && <div className="req-info-text">
                    {isAccepted && !isClosed && UnAcceptBtn}
                    {isOpen ? acceptBtn : closeBtn}
                </div>}
                {this.renderFaces(request)}
                {(this.state.hasError && <div className="error">{this.state.error}</div>) || null}
            </div>
        )
    }

    renderLoadingOrError() {
        const { isFetching, hasError, error } = this.state
        if (isFetching) {
            return (
                <Dimmer active={isFetching} inverted>
                    <Loader active={isFetching} />
                </Dimmer>
            )
        }

        if (hasError && error) {
            return (
                <div className="error">
                    {error} <br />
                    <Link to="/">Go Back</Link>
                </div>
            )
        }
    }

    render() {
        const { request } = this.state
        return (
            <div className="SingleRequest">{request ? this.renderRequest(request) : this.renderLoadingOrError()}</div>
        )
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        profile: authReducer.profile
    }
}

export default withRouter(connect(mapStateToProps)(SingleRequest));