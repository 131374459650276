import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

import * as actions from '../../../../actions/requests'
import './style.less'
import { AppState } from '../../../../reducers'
import Requests from '../../../../components/Requests'
import { formatRequestsDictForCalendarEvents } from '../../../../util/requests';

function mapStateToProps({ requestsReducer, authReducer }: AppState) {
    const isCalendarEvents = Object.keys(requestsReducer.unassignedRequestCalendarEvents || {}).length;
    const finalRequestsArr = isCalendarEvents && requestsReducer.unassignedRequestCalendarEvents ? formatRequestsDictForCalendarEvents(requestsReducer.unassignedRequestCalendarEvents) : requestsReducer.unassignedRequests;
    return {
        requests: requestsReducer.requests,
        hasError: requestsReducer.hasError,
        errorCode: requestsReducer.errorCode,
        isFetching: requestsReducer.fetching,
        page: requestsReducer.page,
        count: requestsReducer.count,
        limit: requestsReducer.limit,
        unassignedRequests: finalRequestsArr,
        unassignedRequestHasError: requestsReducer.unassignedRequestHasError,
        unassignedRequestErrorCode: requestsReducer.unassignedRequestErrorCode,
        unassignedRequestIsFetching: requestsReducer.unassignedRequestFetching,
        unassignedRequestPage: requestsReducer.unassignedRequestPage,
        unassignedRequestCount: requestsReducer.unassignedRequestCount,
        unassignedRequestLimit: requestsReducer.unassignedRequestLimit,
        userId: authReducer.profile && authReducer.profile._id,
        facilityTZ: authReducer.profile && authReducer.profile.FacilityTimeZone || "",
        unassigned: true,
        requestTitle: 'Open Requests',
        emptyMessage: 'No Open Requests',
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, actions.RequestsAction>) {
    return {
        onFetchRequests: (userId: string, pageNo = undefined, filter = undefined, facilityTZ: string = "", fetchAllData: boolean = false, showAllRequests: boolean = false) => dispatch(actions.fetchUnassignedRequests(true, pageNo, filter, facilityTZ, fetchAllData, showAllRequests)),
        onBgFetchRequests: (userId: string, pageNo = undefined, filter = undefined, facilityTZ: string = "", fetchAllData: boolean = false, showAllRequests: boolean = false) => dispatch(actions.fetchUnassignedRequests(false, pageNo, filter, facilityTZ, fetchAllData, showAllRequests)),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Requests))
