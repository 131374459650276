import React, { SetStateAction } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import './style.less';
import { Member } from '../../types/Group';

interface dropdownOptions {
    key: string;
    text: string;
    value: string;
}
interface ScrollableDropDownProps {
    placeholder: string;
    selectedValues: string[];
    dropdownOptions: dropdownOptions[];
    handleValuesChange: (value: string[]) => void;
    setSelectedValues: React.Dispatch<SetStateAction<string[]>>;
    disable?: boolean;
    name: string;
    maxHeight: string;
}

const ScrollableDropDown: React.FC<ScrollableDropDownProps> = ({
    placeholder,
    selectedValues,
    dropdownOptions,
    handleValuesChange,
    setSelectedValues,
    disable,
    name,
    maxHeight
}) => {
    const handleDeleteSelectedStaff = (selectedValue: string) => {
        const value = selectedValues;
        const filteredDropdownValues = value.filter((data) => data !== selectedValue);
        setSelectedValues(filteredDropdownValues);
    };

    return (
        <div style={{ position: 'relative' }}
        onClick={(e) => {
            const targetElement = e.target as HTMLElement;
        
            // Ensure focus only if the click is not on the delete icon or inside the selected list
            if (!targetElement.closest('.deleteIcon') && !targetElement.closest('.selectedList')) {
                const dropdownElement = document.querySelector('.scrollable .search') as HTMLElement;
                if (dropdownElement) {
                    dropdownElement.focus();
                }
            }
        }}
        >
            <Dropdown
                placeholder={placeholder}
                closeOnEscape
                multiple
                value={selectedValues}
                clearable
                search
                scrolling
                selection
                options={dropdownOptions}
                className={selectedValues.length > 1 ? 'scrollable' : ''}
                onChange={(e, { value = '' }) => handleValuesChange((value as string[]) || [])}
                disabled={disable}
                style={{ width: '100%' }}
            />
            {selectedValues.length > 1 && (
                <>
                    <p className="display-text">
                        No of Selected {name}: {selectedValues.length}
                    </p>
                    <div className="selectedList">
                        <h5> Selected {name} </h5>
                        <ul className="scroll-list" style={{ maxHeight: `${maxHeight}` }}>
                            {selectedValues.map((selectedValue, index) => {
                                const selectedUser = dropdownOptions.find((item) => item.value === selectedValue);
                                if (selectedUser) {
                                    return (
                                        <li className="scroll-option" key={index}>
                                            {selectedUser.text}
                                            <Icon
                                                onClick={() => handleDeleteSelectedStaff(selectedValue)}
                                                name="times"
                                                className="deleteIcon"
                                            />
                                        </li>
                                    );
                                }
                            })}
                        </ul>
                    </div>
                </>
            )}
        </div>
    );
};

export default ScrollableDropDown;
