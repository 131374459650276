import API from './API'
import { FetchRequestsResponse } from '../types'
import { Request } from '../types'
import { Promise } from 'bluebird'
import moment from 'moment';
import momentTZLib from 'moment-timezone';
interface getTimestampLimitsResponse {
    startTimestamp: Number
    endTimestamp: Number
}

interface requestFilters {
    Status?: object | string
    RequestedTime?: object
    Sentiment?: number
    AcceptedBy?: string | null
    EscalatedTo?: object
    Registrant?: string
    Department?: string
    Disable?: boolean
}

interface requestOptions {
    Params?: any
    Filter: requestFilters
    QueryOptions: {
        page_no: number
        page_size: number
        sort: object[]
    }
}

function parseFetchResponse(response: any, page: number = 1, limit: number = 100): FetchRequestsResponse {
    const res: FetchRequestsResponse = {
        count: response.TotRecords || 0,
        page,
        limit,
        requests: response.Result,
    }
    return res
}


export async function fetchAllRequests(): Promise<FetchRequestsResponse> {
    const res = await API.lambdaPost(`/requests/list`, {
        Filter: {},
        QueryOptions: {
            page_no: 1,
            page_size: 100,
            sort: [{ RequestedTime: 'asc' }],
        },
    })
    const parsed = parseFetchResponse(res)
    return parsed
}

export async function fetchActiveRequests(page_no = 1, page_size = 100): Promise<FetchRequestsResponse> {
    const res = await API.lambdaPost(`/requests/list`, {
        Filter: {
            Status: { $ne: 'Closed' },
        },
        QueryOptions: {
            page_no,
            page_size,
            sort: [{ RequestedTime: 'asc' }],
        },
    })
    const parsed = parseFetchResponse(res, page_no, page_size)
    return parsed
}

export async function fetchFilteredRequests(
    filters,
    page_no = 1,
    page_size = 100
): Promise<FetchRequestsResponse> {
    const res = await API.lambdaPost(`/requests/listAll`, {
        Filter: {
            ...filters,
        },
        QueryOptions: {
            page_no,
            page_size,
            sort: [{ RequestedTime: 'asc' }],
        },
    })
    const parsed = parseFetchResponse(res, page_no, page_size)
    return parsed
}

export async function fetchRequestsAndReqInstances(filters): Promise<{Result: Request[], TotRecords: number}> {
    return await API.lambdaPost(`/requests/listAll`, filters)
}

export async function fetchUnassignedRequests(pageNo: number | undefined,
    pageSize: number | undefined,
    filter: { startDate: Date, endDate: Date; } | undefined,
    facilityTZ: string = "",
    fetchUpcomingReqDue?: boolean,
    getGroupedCategoryOrDepartmentCount?: boolean,
    showAllRequests?: boolean
): Promise<FetchRequestsResponse> {
    const query: any = {
        Status: ["Open"],
    };
    let startDateTimeString = "";
    let endDateTimeString = "";
    if (fetchUpcomingReqDue) {
        const offset = 3;
        const currentFacilityTime = momentTZLib.tz(new Date(), facilityTZ);
        const threeHoursBefore = currentFacilityTime.clone().subtract(offset, 'hours').format('YYYY-MM-DDTHH:mm:ss');
        const threeHoursAfter = currentFacilityTime.clone().add(offset, 'hours').format('YYYY-MM-DDTHH:mm:ss');
        startDateTimeString = threeHoursBefore;
        endDateTimeString = threeHoursAfter;
    } else {
        if (filter && filter.startDate && filter.endDate) {
            const fromDateString = moment(filter.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'); // we don't support time in the filter
            const toDateString = moment(filter.endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss'); // we don't support time in the filter
            startDateTimeString = fromDateString;
            endDateTimeString = toDateString;
        }

    }
    if (startDateTimeString && endDateTimeString) {
        query.StartDateTime = startDateTimeString;
        query.EndDateTime = endDateTimeString;
    }
    if (getGroupedCategoryOrDepartmentCount) {
        query.getGroupedCategoryOrDepartmentCount = getGroupedCategoryOrDepartmentCount;
    }

    if (showAllRequests) {
        query.FetchAllGroupsReq = true
    }
    const res = await fetchFilteredRequests(query, pageNo, pageSize);
    return res;
}

export async function fetchMyOpenRequests(userId: string, pageNo: number | undefined = undefined, pageSize: number | undefined = undefined, filter: { startDate: Date, endDate: Date; } | undefined = undefined, facilityTZ: string = "", getGroupedCategoryOrDepartmentCount: boolean = false, showAllRequests: boolean = false): Promise<FetchRequestsResponse> {
    const query: any = {
        Status: ['Accepted'],
        AcceptedBy: userId,
    };
    if (filter) {
        const fromDate = filter.startDate;
        const toDate = filter.endDate;
        if (fromDate && toDate) {
            const fromDateString = moment(filter.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'); // we don't support time in the filter
            const toDateString = moment(filter.endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss'); // we don't support time in the filter
            query.StartDateTime = fromDateString;
            query.EndDateTime = toDateString;
        }
    } 
    if (getGroupedCategoryOrDepartmentCount) {
        query.getGroupedCategoryOrDepartmentCount = getGroupedCategoryOrDepartmentCount;
    }

    if (showAllRequests) {
        query.FetchAllGroupsReq = true
    }
    const res = await fetchFilteredRequests(query, pageNo, pageSize)
    return res
}

export async function fetchMyClosedRequests(userId: string, pageNo: number | undefined = undefined , pageSize: undefined | number=undefined , filter=undefined, facilityTZ: string = "", getGroupedCategoryOrDepartmentCount: boolean = false, showAllRequests: boolean = false): Promise<FetchRequestsResponse> {
    const res = await fetchFilteredRequests({
        Status: ['Closed'],
        AcceptedBy: userId,
        getGroupedCategoryOrDepartmentCount: getGroupedCategoryOrDepartmentCount ? getGroupedCategoryOrDepartmentCount : false, 
        showAllRequests: showAllRequests ? showAllRequests : false

    })
    return res
}

export async function fetchEscalatedRequests(page_no = 1, page_size = 100): Promise<FetchRequestsResponse> {
    const res = await fetchFilteredRequests(
        {
            EscalatedTo: { $exists: true }, // backend doesn't support this filter and validation will throw error, this function is not used anywhere for now
            Status: ['Open'],
        },
        page_no,
        page_size,
    )
    return res
}

export async function fetchResolvedRequests(page_no = 1, page_size = 100): Promise<FetchRequestsResponse> {
    const res = await fetchFilteredRequests({ Status: ['Closed'] }, page_no, page_size)
    return res
}

export async function fetchNegativeRequests(page_no = 1, page_size = 100): Promise<FetchRequestsResponse> {
    const res = await fetchFilteredRequests({ Sentiment: 1 }, page_no, page_size) // backend doesn't support this Sentiment filter and validation will throw error, this function is not used anywhere for now
    return res
}

export async function fetchOneRequest(requestId: string): Promise<Request | null> {
    try {
        const res = await API.lambdaGet(`/requests/get?_id=${requestId}`)
        return res as Request
    } catch (e) {
        if (e.statusCode === 404) return null
        throw e
    }
}

export async function acceptRequest(requestId: string): Promise<Request> {
    await API.lambdaGet(`/requests/accept?_id=${requestId}`)
    const updated = await fetchOneRequest(requestId)
    if (!updated) throw new Error('Could not fetch updated request')
    return updated
}

export async function closeRequest(requestId: string): Promise<Request> {
    const request = await fetchOneRequest(requestId)

    if (!request) throw new Error('Could not fetch updated request')
    /* 
       - For standalone requests, we need to send announcement
       - For service requests, we don't need to send announcement
    */

    await API.lambdaPost('/requests/close', {
        _id: requestId
    })
    return request
}

export async function unAcceptRequest(requestId: string): Promise<Request> {
    try {
        const response = await API.lambdaPost('/requests/unaccept', { requestId });
        return response;
    } catch (error) {
        console.error('Something went wrong in unaccepting  request', error);
        throw new Error(`Something went wrong in unaccepting  request ${error}`);
    }
}

///// older single req close 
// export async function closeRequest(requestId: string): Promise<Request> {
//     await API.lambdaGet(`/requests/close?_id=${requestId}`)
//     const updated = await fetchOneRequest(requestId)
//     if (!updated) throw new Error('Could not fetch updated request')
//     return updated
// }
/////

///// older request format 
// export async function closeAllRequests(requestIds: string[]): Promise<Request> {
//     const res = await API.lambdaPost('/requests/close', {
//         _ids: requestIds,
//     })
//     if (!res) throw new Error('Could not close all requests')
//     return res
// }
/////

export async function closeAllRequests(requestIds: string[]) {
    const res = await Promise.map(
        requestIds,
        async (requestId, i) => {
            let body: {
                _id: string
            } = { _id: requestId }

            const res = await API.lambdaPost('/requests/close', body)
            return res
        }, { concurrency: 2 },
    )
    res.forEach(response => {
        if (!response) throw new Error('Could not close all requests')
    })
}

export async function setRequestSentiment(requestId: string, sentiment: number): Promise<Request> {
    await API.lambdaPost(`/requests/sentiment`, {
        _id: requestId,
        Sentiment: sentiment,
    })
    const updated = await fetchOneRequest(requestId)
    if (!updated) throw new Error('Could not fetch updated request')
    return updated
}

export async function assignRequest(requestId: string, userId: string): Promise<Request> {
    await API.lambdaPost(`/requests/assign`, {
        _id: requestId,
        AssignTo: userId,
    })
    const updated = await fetchOneRequest(requestId)
    if (!updated) throw new Error('Could not fetch updated request')
    return updated
}

export async function assignAllRequests(requestIds: string[], userId: string): Promise<Request> {
    const res = await API.lambdaPost(`/requests/assign`, {
        _ids: requestIds,
        AssignTo: userId,
    })
    return res
}
export async function createRequest(request: Partial<Request>): Promise<Request> {
    const created = await API.lambdaPost('/requests/add', request)
    const req = created.RequestData as Request
    return req
}

export async function fetchRequests(
    requestState: string,
    fromDate: Date | null,
    toDate: Date | null,
    userId: string | null,
    staff: string | null,
    resident: string | null,
    department: string | null,
    disable : boolean | null,
    page_no = 1,
    page_size = 100,
    sortBy: object[] = [{ RequestedTime: 'asc' }],
    status?: string,
    facilityTimezone: string = "",
    fetchUpcomingDueReq?: boolean,
    getGroupedCategoryOrDepartmentCount?: boolean,
    FetchCancelRequests?: boolean,
    group?: string,
): Promise<FetchRequestsResponse> {
    const requestOptions: requestOptions | any = {
        Filter: {},
        QueryOptions: {
            page_no,
            page_size,
            sort: sortBy,
        },
    }
    let statusFilter = ""; // to handle the status filter for my requests (accepted or closed)
    if (status){
        statusFilter = requestState;
        requestState = status;
    }
    switch (requestState) {
        case 'openRequests':
            requestOptions.Filter.Status = ['Open'];
            break;

        case 'acceptedAndClosedRequests':
            if (statusFilter === 'accepted') {
                requestOptions.Filter.Status = ['Accepted'];
            } else if (statusFilter === 'resolved') {
                requestOptions.Filter.Status = ['Closed'];
            } else {
                requestOptions.Filter.Status = ['Accepted', 'Closed'];
            }
            break;

        case 'active':
            requestOptions.Filter.Status = ['Open', 'Accepted'];
            break
        case 'all':
            requestOptions.Filter.Status = ['Open', 'Accepted', 'Closed'];
            break;

        case 'unassigned':
            requestOptions.Filter.Status = ['Open'];
            requestOptions.Filter.AcceptedBy = null
            break

        case 'open':
            requestOptions.Filter.Status = ['Accepted']
            if (userId) {
                requestOptions.Filter.AcceptedBy = userId
            }
            break

        case 'closed':
            requestOptions.Filter.Status = ['Closed']
            if (userId) {
                requestOptions.Filter.AcceptedBy = userId
            }
            break

        case 'escalated':
            requestOptions.Filter.Escalated = true
            break

        case 'resolved':
            requestOptions.Filter.Status = ['Closed']
            break

        // case 'negative': // we don't support sentiment filter as of now, we can simply uncomment it later when required
        //     requestOptions.Filter.Sentiment = 1
        //     break

        default:
            break
    }

    if (fromDate && toDate) {
        let startDateTimeString = "";
        let endDateTimeString = "";
        if (fetchUpcomingDueReq) {
            const offset = 3;
            const currentFacilityTime = momentTZLib.tz(new Date(), facilityTimezone);
            const threeHoursBefore = currentFacilityTime.clone().subtract(offset, 'hours').format('YYYY-MM-DDTHH:mm:ss');
            const threeHoursAfter = currentFacilityTime.clone().add(offset, 'hours').format('YYYY-MM-DDTHH:mm:ss');
            startDateTimeString = threeHoursBefore;
            endDateTimeString = threeHoursAfter;
        } else {
            const fromDateString = moment(fromDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'); // we don't support time in the filter
            const toDateString = moment(toDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss'); // we don't support time in the filter
            startDateTimeString = fromDateString;
            endDateTimeString = toDateString;
        }
        if (startDateTimeString && endDateTimeString) {
            requestOptions.Filter.StartDateTime = startDateTimeString;
            requestOptions.Filter.EndDateTime = endDateTimeString;
        }
    }

    if (staff) {
        requestOptions.Filter.AcceptedBy = staff
    }

    if (resident) {
        requestOptions.Filter.Registrant = resident
    }

    if (department) {
        requestOptions.Filter.Department = department
    }

    if (group) {
        requestOptions.Filter.Group = group;
    }

    if (disable) {
        requestOptions.Filter.Disable = disable
    }

    if(getGroupedCategoryOrDepartmentCount){ 
        requestOptions.Filter.getGroupedCategoryOrDepartmentCount = getGroupedCategoryOrDepartmentCount;
    }

    if (FetchCancelRequests) {
        requestOptions.Filter.FetchCancelRequests = FetchCancelRequests;
    }
    
    const res = await API.lambdaPost(`/requests/listAll`, requestOptions)
    console.log( "Fetched data is :",res)
    const parsed = parseFetchResponse(res)
    return parsed
}

export async function reopenStandaloneRequest(requestId: string) {
    try {
        const res = await API.lambdaPost('/requests/reopen', { requestId });
        return res;
    } catch (error) {
        console.error('Something went wrong in reopening standalone request', error);
        throw error;
    }
}
